import React, { useState, useEffect, useLayoutEffect } from "react";
import { SlideCountdown } from "react-fancy-countdown";
import { Line } from "rc-progress";
import BigNumber from "bignumber.js";
import { useContractRead } from "wagmi";
import presaleAbi from "../../../abi/presale.json";
import UsdtCurrency from "../../Currency/UsdtCurrency";
import {
  contractAddr,
  chainId,
  tokenPrice,
  targetUsdAmount,
} from "../../../config";

function HeroHomeForm({ icoCounterClass = "ico-counter dotted-bg" }) {
  const getTotalSold = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "usdRaised",
    watch: true,
    chainId: chainId,
  });

  const totalUsd = new BigNumber(getTotalSold.data)
    .dividedBy(new BigNumber(10).pow(6))
    .toFixed(5); // prev 3
  const targetUsd = new BigNumber(targetUsdAmount); // target usd amount
  const progressPercentage = new BigNumber(totalUsd)
    .dividedBy(targetUsd)
    .multipliedBy(100);

  const [eventDate, setEventDate] = useState("");
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  const today = new Date();
  const newDate = new Date(today.setDate(today.getDate() + 3));

  const year = newDate.getUTCFullYear();
  const month = String(newDate.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-based
  const day = String(newDate.getUTCDate()).padStart(0, "0");
  const hour = String(newDate.getUTCHours()).padStart(2, "0");
  const minute = String(newDate.getUTCMinutes()).padStart(2, "0");
  const second = String(newDate.getUTCSeconds()).padStart(2, "0");
  const strDate =
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;

  console.log("strDate", strDate);

  console.log("timeRemaining", newDate);
  useLayoutEffect(() => {
    setCountdownStarted(true);
    setEventDate(strDate);
    console.log("strDate", eventDate);
    // localStorage.setItem("eventDate", newDate);
  }, []);

  useEffect(() => {
    if (countdownStarted && eventDate) {
      const countdownInterval = setInterval(() => {
        const currentTime = new Date().getTime();
        const eventTime = new Date(eventDate).getTime();
        let remainingTime = eventTime - currentTime;

        if (remainingTime <= 0) {
          remainingTime = 0;
          clearInterval(countdownInterval);
          // localStorage.removeItem("eventDate");
          // alert("Countdown complete!");
          // setEventDate(newDate);
        }

        setTimeRemaining(remainingTime);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [countdownStarted, eventDate, timeRemaining]);

  return (
    <div className={`${icoCounterClass} hero-home-form`}>
      <div className="counter-down">
        <div className="content">
          <div className="conuter-header">
            <h3 className="w-text text-center">TOKEN SALE ENDS IN</h3>
          </div>
          <div className="counterdown-content">
            <div className="count-down titled circled text-center">
              <SlideCountdown weeks={false} deadline={eventDate} />
            </div>
            <h5 className="heading-home-form">
              BUY NOW BEFORE PRICE INCREASES!
            </h5>
            <div className="ico-progress">
              <ul className="list-unstyled list-inline clearfix mb-10">
                <li className="title"></li>
                <li className="strength"></li>
              </ul>
              <div className="current-progress">
                <Line
                  percent={progressPercentage.toNumber()}
                  trailWidth="3"
                  strokeWidth="4"
                  strokeColor="#fb881d"
                />
              </div>
              <span className="pull-left"></span>
              <span className="pull-right"></span>
            </div>
            <p className="white text-center text-16">
              USDT Raised: ${totalUsd} / ${targetUsdAmount}
            </p>
            <p className="white text-center">1 $AIML = ${tokenPrice} USDT</p>
            <UsdtCurrency />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroHomeForm;
