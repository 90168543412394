import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import {
  HowItWorksInfo,
  Features2InfoTop,
  Features2InfoDown,
} from "../../data/data-containers/HomeDataContainer/data-Home.js";

import FaqInfo from "../../data/data-containers/data-FaqInfo.json";

import { HomeDemo1About1 } from "../../utils/allImgs";

import "./style/HomeDemo2.scss";

import Header from "../../layouts/Header";
import FooterPages from "../../layouts/Footer/FooterPages";

import AboutOther from "../../components/Common/AboutOther";
import SecAbout from "../../components/Common/SecAbout";

import Features2 from "../../components/PageHome/Features2";
import Faq from "../../components/PageHome/Faq";
import OurTeam from "../../components/Common/OurTeam";

import HowItWorks from "../../components/Common/HowItWorks";
import { HomeDemo1Computer } from "../../utils/allImgs";
import SecHeroSection from "../../components/Common/HeroSection";
import { OurTeamInfo } from "../../components/Common/OurTeam/OurTeamInfo.js";
import Counter from "../../components/PageHome/Features2/Counter/index.jsx";
import HeroHomeForm from "../../components/Common/HeroHomeForm/HeroHomeForm.jsx";
import AIMLTelegrambot from "../../assets/img/AIMLTelegrambot.png";
import { useNavigate } from "react-router-dom";

const HomeContainer = () => {
  const navigate = useNavigate();

  // const gotToNewPage = () => {
  //   navigate("/https://linktr.ee/AltCoinsMoney");
  // };

  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div>
      <Header Title="Altcoins Money" />
      <SecHeroSection
        ClassSec="hero-section app section-padding"
        ClassDiv="col-12 col-lg-6 col-md-12"
        specialHead="Welcome to AltCoinsMoney"
        title="An AI and Blockchain-Enabled Innovation Platform"
        description="At its core, our platform harnesses blockchain technology aiming to redefine the decentralized finance (DeFi) arena. Integrating predictive AI, automated trading, and robust investor fund safeguards, we are shifting paradigms in how you experience cryptocurrencies"
        link1="Whitepaper"
        link2="Audit"
        showForm={true}
      />
      <div
        className="hero-section-content"
        style={{ backgroundColor: "#846FF4" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-4 col-md-12">
              <div className="promo-section d-flex justify-content-center">
                <img
                  className="pt-4 "
                  src={AIMLTelegrambot}
                  alt=""
                  style={{ height: "150px", width: "150px" }}
                />
              </div>
            </div>

            <div className="col-lg-6 text-start">
              <h5 className=" d-flex justify-content-center">
                Scan QR To Get AIML Airdrop Via Telegram{" "}
              </h5>
              <h6 className=" d-flex justify-content-center">
                Socially Mine AIML Token
              </h6>
            </div>

            <div className="col-lg-2 mb-2 d-flex justify-content-center">
              <button
                type="button"
                className="btn dream-btn mt-28 fadeInUp p-1"
                data-wow-delay="0.6s"
                onClick={() => openInNewTab("https://linktr.ee/AltCoinsMoney")}
              >
                GET AIRROPS
              </button>
            </div>
          </div>
        </div>
      </div>
      <Features2
        icoCounterClass="ico-counter dotted-bg mb-30"
        Features2InfoTop={Features2InfoTop}
        Features2InfoDown={Features2InfoDown}
      />
      <AboutOther
        subtext="About The Platform"
        heading="At The Core of AltCoinsMoney"
        description="At its core, our platform harnesses blockchain technology aiming
                to redefine the decentralized finance (DeFi) arena. Integrating
                predictive AI, automated trading, and robust investor fund
                safeguards, we are shifting paradigms in how you experience
                cryptocurrencies."
        img={HomeDemo1Computer}
      />
      <br /> <br /> <br />
      <HowItWorks
        title="Envision And Achieve"
        text="Advanced Predictive AI"
        description="Possess the ability to discern market tendencies, forecast price shifts, and pinpoint burgeoning opportunities, all in real-time. Our state-of-the-art predictive AI models grant you a strategic edge."
        data={HowItWorksInfo}
        ClassSpanTitle="gradient-text"
      />
      <SecAbout
        imgDwon={false}
        title="Introducing the LaunchPad"
        text="The Future of Fundraising: Your Bridge to Crypto Success"
        img={HomeDemo1About1}
        isHome={true}
        isLaunchpad={false}
      />
      <div className="clearfix" />
      <Faq data={FaqInfo} ClassSpanTitle="gradient-text" />
      <OurTeam data={OurTeamInfo} ClassSpanTitle="gradient-text" />
      <FooterPages ClassSpanTitle="gradient-text" />
    </div>
  );
};

export default HomeContainer;
