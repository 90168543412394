import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import ConnectWalletButton from "../ConnectWalletButton";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import { parseUnits } from "viem";

import presaleAbi from "../../abi/presale.json";
import erc20Abi from "../../abi/erc20.json";
import {
  tokenAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  tokenPrice,
} from "../../config";

import AIMLReceivedImg from "../../assets/img/icons/header-logo.png";

export default function UsdtCurrency() {
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtErrorMessage, setUsdtErrorMessage] = useState("");
  const [allowance, setAllowance] = useState(new BigNumber(0));

  const { address, isConnected } = useAccount();
  console.log("Address:", address);
  console.log("isConnected:", isConnected);

  const balanceUsdt = useBalance({
    address,
    token: tokenUsdtAdd,
    chainId,
    enabled: !!address,
    watch: true,
  });
  console.log("USDT Balance:", balanceUsdt);

  const balanceToken = useBalance({
    address,
    token: tokenAdd,
    chainId,
    enabled: !!address,
    watch: true,
  });
  console.log("Token Balance:", balanceToken);

  const usdtBalanceBig = new BigNumber(balanceUsdt.data?.formatted || 0);
  const isValidUsdt = usdtBalanceBig.gte(usdtAmount);
  console.log("isValidUsdt:", isValidUsdt);

  const { config: approveConfig } = usePrepareContractWrite({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "approve",
    args: [contractAddr, parseUnits(usdtAmount, 6)],
    enabled: !!address && !!usdtAmount && !!isValidUsdt,
    chainId,
  });
  console.log("Approve Config:", approveConfig);

  const { data: approveData, write: approveWrite } = useContractWrite(approveConfig);
  console.log("Approve Data:", approveData);

  const { isLoading: approveIsLoading, isSuccess: approveIsSuccess, isError: approveIsError } = useWaitForTransaction({
    hash: approveData?.hash,
  });
  console.log("Approve Transaction Status:", { approveIsLoading, approveIsSuccess, approveIsError });

  const allowanceUsdtGet = useContractRead({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address, contractAddr],
    watch: true,
    chainId,
  });
  console.log("Allowance:", allowanceUsdtGet);

  useEffect(() => {
    if (allowanceUsdtGet.data !== undefined) {
      setAllowance(new BigNumber(allowanceUsdtGet.data));
    }
  }, [address, allowanceUsdtGet.data]);

  const { config: purchaseConfig } = usePrepareContractWrite({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "buyTokensWithUSDT",
    args: [parseUnits(usdtAmount, 6)],
    enabled: !!address && allowance.gt(parseUnits(usdtAmount, 6)) && !!usdtAmount,
    chainId,
  });
  console.log("Purchase Config:", purchaseConfig);

  const { data: purchaseData, write: purchaseWrite } = useContractWrite(purchaseConfig);
  console.log("Purchase Data:", purchaseData);

  const { isLoading: purchaseIsLoading, isSuccess: purchaseIsSuccess, isError: purchaseIsError } = useWaitForTransaction({
    hash: purchaseData?.hash,
  });
  console.log("Purchase Transaction Status:", { purchaseIsLoading, purchaseIsSuccess, purchaseIsError });

  useEffect(() => {
    if (purchaseIsSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! Purchase Complete
          <div>
            <a style={{ color: "#fff" }} href={`https://polygonscan.com/tx/${purchaseData?.hash}`} target="_blank" rel="noopener noreferrer">
              View On Polygon
            </a>
          </div>
        </div>
      );
    }
  }, [purchaseIsSuccess, purchaseData?.hash]);

  useEffect(() => {
    if (purchaseIsError) {
      toast.error(
        <div className="text-center py-2">Error! Something Went Wrong</div>
      );
    }
  }, [purchaseIsError]);

  useEffect(() => {
    if (purchaseData?.hash) {
      setAllowance(new BigNumber(0));
    }
  }, [purchaseData?.hash]);

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;
    const parsedAmount = parseFloat(inputValue);

    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      setUsdtErrorMessage("Amount must be greater than zero");
    } else if (balanceUsdt.data?.formatted < parsedAmount) {
      setUsdtErrorMessage("Insufficient balance.");
    } else {
      setUsdtErrorMessage("");
    }
    setUsdtAmount(inputValue);
  };

  const getAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "getTokenAmountUSDT",
    args: [parseUnits(usdtAmount, 6)],
    enabled: !!usdtAmount,
    watch: true,
    chainId,
  });
  console.log("Get Amount:", getAmount);

  const result = new BigNumber(getAmount.data || 0).toNumber();

  return (
    <div className="home-form">
      <form>
        <div className="home-form-input-container">
          <div>
            <div className="flex content-space-between">
              <div>Enter USDT Amount</div>
              <div>Max</div>
            </div>
            <div style={{ position: "relative" }}>
              <input
                className="inputbox-home-hero"
                placeholder={0}
                value={usdtAmount}
                onChange={handleAmountChange}
                step="any"
                style={{ borderRadius: "5px" }}
              />
              <div style={{ position: "absolute", width: "30px", right: "0px", bottom: "3px" }}>
                <img
                  src="https://static.vecteezy.com/system/resources/previews/013/373/699/original/tether-usdt-3d-rendering-isometric-icon-free-png.png"
                  alt="USDT"
                  style={{ marginLeft: "-5px" }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex content-space-between">
              <div>$AIML You Receive</div>
            </div>
            <div style={{ position: "relative" }}>
              <input
                className="inputbox-home-hero"
                placeholder={result}
                style={{ borderRadius: "5px" }}
                readOnly
              />
              <div style={{ position: "absolute", width: "30px", right: "0px", bottom: "3px" }}>
                <img
                  src={AIMLReceivedImg}
                  alt="$AIML"
                  style={{ marginLeft: "-5px" }}
                />
              </div>
            </div>
          </div>
        </div>
        {usdtErrorMessage && <p style={{ color: "red" }}>{usdtErrorMessage}</p>}
        <div className="text-center">
          {isConnected ? (
            <>
              <br />
              <div className="text-center" style={{ fontSize: "16px", color: "#fff" }}>
                YOUR AVAILABLE $AIML = {balanceToken?.data?.formatted}
              </div>
              <br />
              {allowance.lt(parseUnits(usdtAmount, 6)) ? (
                <button
                  type="button"
                  className="btn dream-btn mt-30 fadeInUp"
                  data-wow-delay="0.6s"
                  disabled={!approveWrite || approveIsLoading}
                  onClick={() => {
                    console.log("Approve Write Called");
                    approveWrite();
                  }}
                >
                  {approveIsLoading ? "Approving..." : "Approve"}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn dream-btn mt-30 fadeInUp"
                  data-wow-delay="0.6s"
                  disabled={!purchaseWrite || purchaseIsLoading}
                  onClick={() => {
                    console.log("Purchase Write Called");
                    purchaseWrite();
                  }}
                >
                  {purchaseIsLoading ? "Buying..." : "Buy Now"}
                </button>
              )}
            </>
          ) : (
            <div>
              <ConnectWalletButton />
            </div>
          )}
          {isConnected && (
            <div>
              <ConnectWalletButton />
            </div>
          )}
        </div>
      </form>
      <Toaster />
    </div>
  );
}
